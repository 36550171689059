<template>
    <div class="bg-info">
        <div>
            <h4 class="text-white">Jet Dock Wisconsin</h4>
            <h4 class="text-white">Your Local Jet Dock Dealer</h4>
        </div>
        <div class="bg-info">
          <div class="row">
            <div class="col"><h2 class="text-white justiy-content-center ">Mike Obradovich</h2></div>
          </div>
          <div class="row">
            <div class="col"><h2 class="text-white justiy-content-center">(262) 446-0016  </h2></div>
          </div>
          <div class="row">
            <div class="col"><h2 class="text-white justiy-content-center">JetDockWI@gmail.com</h2></div>
          </div>
          <div class="row">
            <div class="col"><p class="text-white font-weight-bold">Submit your information and we can assist you with purchasing your new Jet Dock</p></div>
          </div>
          </div>
          <div class="col">
    <form ref="form" @submit.prevent="sendEmail" class="form-control-sm">
      <div class="form-group container-fluid">
      <div class="row">
        <div class="col">
          <label for="first_name">First Name</label>
          <input type="text" class="form-control-sm" id="first_name" placeholder="First Name" name = first_name required />
        </div>
        <div class="col">
          <label for="last_name">Last Name</label>
          <input
            type="text"
           class="form-control-sm"
            id="last_name"
            placeholder="Last Name"
            name = last_name
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="from_email">Email Address</label>
          <input
            type="email"
            class="form-control-sm"
            id="from_email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            name = user_email
            required 
          />
        </div>
        <div class="col">
          <label for="phone">Cell Phone Number</label>
          <input
            type="text"
            class="form-control-sm"
            id="phone"
            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
            placeholder="262-555-5555 or 2625555555"
            name = phone_number
            required 
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="address">Address</label>
          <input
            type="address"
            class="form-control-sm"
            id="address"
            aria-describedby="address"
            placeholder="Address"
            name = address
            required 
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="city">City</label>
          <input
            type="city"
            class="form-control-sm"
            id="city"
            aria-describedby="city"
            placeholder="City"
            name = city
            required 
          />
        </div>
        <div class="col">
          <label for="state">State</label>
          <input
            type="state"
            class="form-control-sm"
            id="state"
            aria-describedby="state"
            placeholder="State"
            name = state
            required 
          />
        </div>
        <div class="col">
          <label for="zip">Zip Code</label>
          <input
            type="zip"
            class="form-control-sm"
            id="zip"
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            placeholder="5 Digit Zip Code"
            name = zip
            required 
          />
        </div>
      </div>
      <div class="container bg-info d-flex justify-content-center">
      <div class="row">
        <div class="col">
          <label for="inputGroupSelect01">I am interested in</label>
          <select class="custom-select form-control-sm" id="inputGroupSelect01" name = lift_type required >
            <option value="" disabled selected hidden>Select type of Lift/Dock</option>
            <option value="Boat Lift">Boat Lift</option>
            <option value="PWC Lift">PWC Lift</option>
            <option value="PWC Lift">Kayak Lift</option>
            <option value="Floating Dock/Walkway">Floating Dock/Walkway</option>
            <option value="Seaplane Dock">Seaplane Dock</option>
          </select>
          </div>
        </div>
      </div>
      <div > <h4 class="text-white">Watercraft Information</h4>
      <div class=row>
        <div class="col"><label for="make">Make</label><input type="text" class="form-control-sm" id="make" placeholder="Watercraft Make" name = make /></div>
        <div class="col"><label for="model">Model</label><input type="text" class="form-control-sm" id="model" placeholder="Watercraft Model" name = model /></div>
        <div class="col"><label for="year">Year</label><input type="text" class="form-control-sm" id="year" placeholder="Watercraft Year" name = year /></div>
      </div>
       <div class=row>
        <div class="col"><label for="beam">Beam</label><input type="text" class="form-control-sm" id="beam" placeholder="Beam" name = beam /></div>
        <div class="col"><label for="weight">Weight</label><input type="text" class="form-control-sm" id="weight" placeholder="Weight" name = weight /></div>
        <div class="col"><label for="power">Power Train</label>
          <select class="custom-select form-control-sm" id="power" name = power>
            <option selected disabled hidden>Select Power Train of Watercraft</option>
            <option value="Inboard">Inboard</option>
            <option value="I/O">I/O</option>
            <option value="Outboard">Outboard</option>
            <option value="Jet">Jet</option>
          </select>
        <!-- <input type="text" class="form-control" id="power" placeholder="Power" name = power /> -->
        </div>
      </div>
      </div>
       <div > <h4 class="text-white">Location Information</h4>
      <div class=row>
        <div class="col">
          <label for="docklocation" >Where do you need a lift/dock?</label>
          <!-- <input type="text" class="form-control" id="where" placeholder="Where?" name = where /> -->
          <select class="custom-select form-control-sm" id="docklocation" name = docklocation>
          <option selected disable hidden>Select Location of Lift/Dock</option>
          <option value="Home with a Dock">Home with a Dock</option>
          <option value="Marina/Condo/Slip">Marina/Condo/Slip</option>
          <option value="No Existing Dock">No Exsisting Dock</option>
          <option value="Other">Other</option>
        </select>
          </div>
            <div class="col"><label for="make">Water Depth at front of dock site</label><input type="text" class="form-control-sm" id="depfront" placeholder="Depth at Front?" name = depfront /></div>
            <div class="col"><label for="make">Water Depth at back of dock site</label><input type="text" class="form-control-sm" id="depback" placeholder="Depth at Back?" name = depback /></div>         
            <div class="col"><label for="make">Dimentions of Marina Slip</label><input type="text" class="form-control-sm" id="marinadim" placeholder="Marina Dimensions(If Applicable)?" name = marinadim /></div>
       
      </div>
     </div>
      <div class="row">
        <label>Message</label>
        <textarea name="message"></textarea>
        <input type="submit" value="Send" class="form-control-sm" name = message>
      </div>
    </div>
    </form>
 <div class="row"><li><a href="https://marinecu.force.com/REG/s/registration?PartnerCode=0018Y00002rZd2w" class="text-decoration-none"> CLICK HERE:  Jet Dock Wisconsin - credit application</a></li></div>

   </div>
 </div>
</template>

<script>
import emailjs from "@emailjs/browser";
// import { init } from "@emailjs/browser";
// init("user_e4UUvB6NgA33FxEfwzuOh");

export default {
  name: "cform2",
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_5fhxiwh", //Mikes
          // "service_utdjd2g", //dales
          "template_dnzjsci", //Mikes
          // "template_7gm3l6p", //dales
          this.$refs.form,
          "user_4Q4barABLFS5aqF8YActd" //Mikes
          // "user_e4UUvB6NgA33FxEfwzuOh" //dales
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  /* box-sizing: content-box; */
}

.container {
  display: block;
  margin: auto;
  text-align: center;
  /* border-radius: 5px; */
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}
.container-fluid {
    height: 100%;
    overflow-y: hidden; /* don't show content that exceeds my height */
}
.container input {
  width: 100%;
  clear: both;
}

label {
  color: white;
  float: left
}

input[type="text"],
[type="email"],
[type="city"],
[type="zip"],
[type="address"],
[type="state"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
</style>